import { SocialApiTypes } from '@wix/social-groups-api/dist/src/types';

import { serializable } from 'serializr';

export class SocialIdentity implements SocialApiTypes.SocialIdentity {
  @serializable
  identityId!: string;

  @serializable
  identityType!: SocialApiTypes.IdentityType;
}

export class SocialText implements SocialApiTypes.Text {
  @serializable
  contentType!: SocialApiTypes.ContentType;

  @serializable
  content!: string;
}
