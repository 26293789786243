import { ActivityApi } from '@wix/social-groups-api';
import {
  ApiTypes,
  SocialApiTypes,
} from '@wix/social-groups-api/dist/src/types';
import { canSeeGroup } from '@wix/social-groups-api/dist/src/model/Member/permissions';
import moment from 'moment';
import { Controller } from '../Controller';
import { ActivityControllerProps } from './ActivityControllerProps';
import { ControllerParams } from '@wix/yoshi-flow-editor';

export class ActivityController extends Controller<ActivityControllerProps> {
  private api: ActivityApi;

  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);
    this.api = new ActivityApi(this.getSiteToken()!, this.getApiBaseUrl());
    this.onUserLogin(() => {
      this.api = new ActivityApi(this.getSiteToken()!, this.getApiBaseUrl());
    });
  }

  setProps(props: Partial<ActivityControllerProps>) {
    this.controllerConfig.setProps(props);
  }
  async pageReady(): Promise<void> {
    this.setProps({
      activities: undefined,
      activityActions: {
        getActivity: this.getActivity,
      },
    });
  }

  getNewPosts(activities: SocialApiTypes.QueryActivityStatsResponse) {
    try {
      const activityStat = activities.activityStats![0];
      const stat = activityStat.stats!['wix.feed.activities.PostedInFeed'];
      return stat.total;
    } catch (e) {
      this.errorLogger.log(e);
      return 0;
    }
  }

  private readonly getActivity = async (group: ApiTypes.v1.GroupResponse) => {
    if (!canSeeGroup(group)) {
      return;
    }
    const from = moment().subtract(30, 'days').toISOString();
    let newPosts = 0;
    try {
      const { data } = await this.api.getActivities(group.groupId!, {
        time: { from },
      });
      newPosts = this.getNewPosts(data)!;
    } catch (e) {
      console.error('Get group activity: FAIL');
      this.errorLogger.log(e);
    }
    this.setProps({ activities: { newPosts } });
  };
}
