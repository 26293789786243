import {
  ContextTokenService,
  SocialContentService,
} from '@wix/social-groups-api';
import { Controller } from '../Controller';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import {
  IMediaControllerState,
  MediaControllerProps,
  MEDIA_SCOPE,
} from './MediaControllerProps';
import { flatten } from '../../utils/utils';
import { getSocialApiBaseUrl } from '../../../../common/utils/baseUrl';
import { ProGalleryConverter } from '../../../../common/ContentEditor/plugins/pro-gallery/ProGalleryConverter';
import { EFilterKeys } from '../../types/EFilterKeys';
import { ControllerParams } from '@wix/yoshi-flow-editor';

export class MediaController extends Controller<MediaControllerProps> {
  repository!: SocialContentService;

  constructor(
    controllerContext: ControllerParams,
    private group: ApiTypes.v1.GroupResponse,
  ) {
    super(controllerContext, group.groupId!);
    this.onUserLogin(this.handleUserLogin);

    this.init();
  }

  handleUserLogin = () => {
    return this.init();
  };

  pageReady() {
    return Promise.resolve();
  }

  protected async init() {
    const groupId = await this.getGroupId();
    const token = this.getSiteToken();

    const location = this.getLocation();
    const baseUrl = getSocialApiBaseUrl(location.baseUrl);

    this.repository = new SocialContentService(
      token!,
      groupId!,
      new ContextTokenService(token!, this.getApiBaseUrl()),
      baseUrl,
    );
  }

  setProps(props: Partial<IMediaControllerState>) {
    return this.controllerConfig.setProps({
      [MEDIA_SCOPE]: {
        fetch: this.fetch,
        ...props,
      },
    });
  }

  async getInitialProps(): Promise<MediaControllerProps> {
    return {
      [MEDIA_SCOPE]: {
        fetch: this.fetch,
      },
    };
  }

  protected fetch = async () => {
    const { data } = await this.repository.feed.search({
      filter: {
        [EFilterKeys.ATTACHMENTS_TYPE]: {
          $in: ['IMAGE', 'VIDEO'],
        },
      },
    });

    const items = data.feedItems!.map((fi) =>
      ProGalleryConverter.convertContentEntity(fi.entity as any, fi.createdBy!),
    );

    this.setProps({ items: flatten(items), total: Number(data.total) });
  };
}
