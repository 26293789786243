import {
  I$WWrapper,
  IControllerConfig,
  IWidgetController,
  IWidgetControllerConfig,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import { configure } from 'mobx';
import { isDefaultGroup } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { DEFAULT_APPS, getDefaultPost } from '../helpers';
import { ISiteNavigation } from './SiteNavigation';

import { GroupAppsMap } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupAppsMap';
import { MainControllerProps } from './MainControllerProps';
import { GroupApps } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupApps';
import { TranslationDataProps } from '../../../../common/types/TranslationDataProps';
import { ErrorHandlerControllerProps } from '../errorHandler/ErrorHandlerControllerProps';
import { Controller } from '../Controller';
import { BaseWidgetController } from '../../../../common/controllers/BaseWidgetController';
import { AppData } from '../../contexts/AppData/IAppData';
import { CurrentMember } from '../members/CurrentMember';
import { SiteMembers } from '../members/SiteMembers';
import { SeoGroupBuilder } from '../../seo/SeoGroupBuilder';
import { checkRTL } from '../../../../common/utils/checkRTL';
import { ControllerError } from '../errorHandler/ControllerError';
import { ErrorHandlerController } from '../errorHandler/ErrorHandlerController';
import {
  AppToastsController,
  MemberInvitesController,
  Tab,
} from '../../../../common/controllers';
import { ConsoleLogger, LogLevel } from '../../../../common/loggers';
import { COMPONENT_ID, PAGE_TITLES } from '../../../../common/utils/utils';
import { MembersController } from '../members/MembersController';
import { NotificationsController } from '../notifications/NotificationsController';
import { ActivityController } from '../activity/ActivityController';
import { CommentsController } from '../comments/CommentsController';
import { EventsController } from '../events/EventsController';
import { GroupController } from '../group/GroupController';
import { ITopic } from '../../types/ITopic';
import { IFeedItem } from '../../types/IFeedItem';
import { FeedController } from '../feed/FeedController';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { MediaController } from '../media/MediaController';

export interface MockGroupProps {
  group?: ApiTypes.v1.GroupResponse;
  apps?: GroupAppsMap;
  feedItems?: IFeedItem[];
  feedFilters?: {};
  feedTopics?: ITopic[];
}

export interface ControllerProps
  extends MainControllerProps,
    AppData,
    TranslationDataProps,
    SiteMembers,
    CurrentMember,
    MockGroupProps,
    ErrorHandlerControllerProps {}

export class MainController extends Controller<ControllerProps> {
  exports: any;

  private controllers!: BaseWidgetController<any>[];
  private seoGroup!: SeoGroupBuilder;

  async pageReady($w?: I$WWrapper, wixAPI?: IWixAPI): Promise<any> {
    // Props for SSR
    await this.setInitialProps();

    if (this.isSSR()) {
      this.reportAppLoaded();
    }
    // Props after SSR
    this.controllers.map((controller) => controller.pageReady($w, wixAPI));
  }

  onBeforeUnLoad() {
    this.controllers.forEach((ctrl) => ctrl.onBeforeUnLoad());
  }

  constructor(
    controllerContext: ControllerParams,
    private readonly group: ApiTypes.v1.GroupResponse,
  ) {
    super(controllerContext, group! && group.groupId!);
    MainController.setLogger(this.controllerConfig);
    this.setControllers(this.controllerConfig, group);
    this.getLocation().onChange(this.handleLocationChange);
    this.onUserLogin(async () => {
      this.controllerConfig.setProps({
        instance: this.getSiteToken(),
      });
    });
  }

  private async getMockGroupProps(): Promise<MockGroupProps> {
    const post = await getDefaultPost(this.controllerConfig);
    return {
      group: this.group,
      apps: DEFAULT_APPS,
      feedItems: [post],
      feedFilters: {},
      feedTopics: [],
    };
  }

  updateConfig($w: I$WWrapper, updatedConfig: IControllerConfig) {
    return Promise.all(
      this.controllers.map(
        (controller: IWidgetController) =>
          controller.updateConfig && controller.updateConfig($w, updatedConfig),
      ),
    ).catch((e) => {
      console.error('Controllers update config error', e);
      this.errorLogger.log(e);
    });
  }

  setSEOData = async () => {
    const { activeTab } = await this.getUrlSegments();
    const { url } = this.getLocation();
    this.seoGroup.forLocation(url, activeTab);
    const seo = this.seoGroup.build();
    return this.controllerConfig.wixCodeApi.seo.renderSEOTags(seo);
  };

  private readonly handleLocationChange = async () => {
    await this.setSEOData();
  };

  private async setInitialProps(): Promise<void> {
    const logLevel = MainController.getLogLevel(this.controllerConfig);

    const { instanceId } = this.controllerConfig.appParams;
    const siteNavigation = await this.getSiteNavigation();
    const tabsUrls = this.group ? await this.getTabsUrls(this.group.slug!) : {};
    const initialProps: Partial<ControllerProps> = {
      logLevel,
      instanceId,
      instance: this.getSiteToken(),
      language: this.getSiteLanguage(),
      reportAppLoaded: this.reportAppLoaded,
      isRTL: checkRTL(this.getSiteLanguage()),
      siteNavigation,
      navigateToLink: this.navigateToLink,
      isEditor: this.isEditorMode(),
      viewMode: this.controllerConfig.wixCodeApi.window.viewMode,
      tabsUrls,
    };

    try {
      const errorEvents = {} as any;
      const controllersProps = await Promise.all(
        this.controllers.map((ctrl) =>
          ctrl.getInitialProps().catch((e) => {
            console.log('Get initial props FAIL:', e);
            this.errorLogger.log(e);
            // TODO: 💩
            if (e.getErrorEvent) {
              const errEvent = (e as ControllerError).getErrorEvent();
              errorEvents[errEvent.origin] = errEvent;
            }
          }),
        ),
      );
      const flattenProps = controllersProps.reduce(
        (acc, props) => ({ ...acc, ...props }),
        {},
      );
      Object.assign(initialProps, flattenProps);
      Object.assign(initialProps.errorEvents, errorEvents);
    } catch (e) {
      console.error('Get Initial Controllers Props: FAIL');
      this.errorLogger.log(e);
    }

    if (this.group) {
      if (isDefaultGroup(this.group)) {
        const defaultGroupProps = await this.getMockGroupProps();
        Object.assign(initialProps, defaultGroupProps);
      }
      await this.initSeo(initialProps);
    }

    this.controllerConfig.setProps(initialProps);
  }

  private async initSeo(initialProps: Partial<ControllerProps>) {
    this.seoGroup = new SeoGroupBuilder().withGroup(this.group);
    try {
      const { feedItems, siteMembers, apps, translation } = initialProps;
      const groupApps = GroupApps.fromAppsMap(apps!);
      const { url } = this.getLocation();
      const { activeTab } = await this.getUrlSegments();
      this.seoGroup
        .withFeed(feedItems)
        .withMembers(siteMembers || [])
        .withTabs(
          groupApps.getTabsMap(translation as { [key: string]: string }),
        )
        .forLocation(url, activeTab);
      await this.setSEOData();
    } catch (e) {
      console.log('[MainController.initSeo] Failed');
    }
  }

  private getLocationValues() {
    // IWixAPI location is not serializable 🤷‍
    const { url, baseUrl, path, query } = this.getLocation();
    return { url, baseUrl, path, query };
  }

  private setControllers(
    controllerConfig: IWidgetControllerConfig,
    group: ApiTypes.v1.GroupResponse,
  ) {
    const groupId = group && group.groupId;
    const errorHandlerController = new ErrorHandlerController(
      this.controllerContext,
      groupId!,
    );
    if (!group) {
      this.controllers = [errorHandlerController];
      return;
    }
    configure({ isolateGlobalState: true });
    if (isDefaultGroup(group)) {
      this.controllers = [];
    } else {
      this.controllers = [
        errorHandlerController,
        new AppToastsController(this.controllerContext),
        new GroupController(this.controllerContext, group),
        new MembersController(this.controllerContext, group),
        new EventsController(this.controllerContext, groupId!),
        new FeedController(this.controllerContext, group),
        new MediaController(this.controllerContext, group),
        new ActivityController(this.controllerContext, groupId!),
        new NotificationsController(this.controllerContext, groupId!),
        new MemberInvitesController(this.controllerContext),
        new CommentsController(this.controllerContext, group),
      ];
    }
  }

  private static setLogger(controllerConfig: IWidgetControllerConfig) {
    ConsoleLogger.mode = MainController.getLogLevel(controllerConfig);
  }

  private static getLogLevel(controllerConfig: IWidgetControllerConfig) {
    let logLevel = LogLevel.NONE;
    try {
      if (controllerConfig.wixCodeApi.location.query.debug === 'true') {
        logLevel = LogLevel.LOG;
      }
      if (controllerConfig.wixCodeApi.location.query.trace === 'true') {
        logLevel = LogLevel.TRACE;
      }
    } catch (e) {}
    return logLevel;
  }

  getTranslationsUrl(): string {
    const { staticsGroupBaseUrl, staticsBaseUrl } = this.getBaseUrls();

    // editor and preview mode fallback
    const baseUrl = staticsGroupBaseUrl || staticsBaseUrl;
    const language = this.getSiteLanguage();
    return `${baseUrl}assets/locales/messages_${language}.json`;
  }

  private readonly reportAppLoaded = () => {
    const fedopsLogger = this.getFedopsLogger();
    fedopsLogger.appLoaded();
  };

  private async getSiteNavigation(): Promise<ISiteNavigation[]> {
    const { viewMode } = this.controllerConfig.wixCodeApi.window;

    // In editor mode we can't find groups list page unless we check for it's name
    // group page isn't present at all
    // So, it's better to hardcode it
    if (viewMode === 'Editor') {
      return Array.from({ length: 3 }, (_, i) => ({ id: String(i) }));
    }

    // editor -> navigateToSection
    // live site -> href
    try {
      const groupPages = await this.getGroupPages(true);

      // In preview we can't determine home page, because 'isHomePage' flag is missing
      // Until it's fixed we just patch it, however a click on home page won't do anything
      if (viewMode === 'Preview') {
        if (groupPages.length < 3) {
          groupPages.unshift({
            id: 'mocked_page_id',
            isHomePage: true,
            name: 'HOME',
            url: '/home',
          });
        }
      }

      const siteNav: ISiteNavigation[] = [];
      const { url, relativeUrl } = await this.getSectionUrl(
        COMPONENT_ID.GROUP_LIST,
      );
      for (const [i, page] of groupPages.entries()) {
        const nav: ISiteNavigation = { id: `#${page.id}` };
        if (page.isHomePage) {
          const baseUrl = this.getLocation().baseUrl;
          if (baseUrl) {
            nav.href = baseUrl;
          }
          siteNav[0] = nav;
        }
        // TODO rethink determination logic. Maybe based on order?)
        if (page.url === relativeUrl) {
          if (!this.isEditorMode()) {
            nav.href = url;
          }
          siteNav[1] = nav;
        }
        if (page.url === '/group') {
          siteNav[2] = nav;
        }
      }
      return siteNav;
    } catch (e) {
      console.log('[MainController.getSiteNavigation] Error');
      return [];
    }
  }

  private readonly navigateToLink = (pageId: string) => {
    this.controllerConfig.wixCodeApi.location.navigateTo?.({
      pageId,
      type: 'PageLink',
    });
  };

  private async getTabsUrls(slug: string) {
    if (this.isEditorMode()) {
      return {} as any;
    }
    try {
      const tabUrls = await Promise.all(
        Object.values(Tab).map(async (tab) => {
          return [tab, await this.getGroupUrl({ groupId: slug, tabName: tab })];
        }),
      );

      return Object.fromEntries(tabUrls) as { [key in Tab]: string };
    } catch (e) {
      console.log('[MainController.getTabsUrls] Error');
      this.errorLogger.log(e);
    }
    return {} as any;
  }
}
