import { Controller } from '../Controller';
import {
  getNotificationSettingsMap,
  getNotificationSettingsRequest,
  NotificationsApi,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  GroupNotificationSettings,
  NotificationSettings,
} from './NotificationsControllerProps';
import { ControllerParams } from '@wix/yoshi-flow-editor';

const { WEB, MOBILE, EMAIL } = ApiTypes.v1.NotificationChannel;
const CHANNELS = [EMAIL, MOBILE, WEB];
export class NotificationsController extends Controller<NotificationSettings> {
  private readonly api: NotificationsApi;

  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);
    this.api = new NotificationsApi(this.getSiteToken()!, this.getApiBaseUrl());
  }
  setProps(props: Partial<NotificationSettings>) {
    this.controllerConfig.setProps(props);
  }
  pageReady(): Promise<any> {
    this.setProps({
      notificationActions: {
        getNotificationSettings: this.getNotificationSettings,
        setNotificationSettings: this.setNotificationSettings,
      },
      notificationSettings: undefined,
    });
    return Promise.resolve();
  }

  private readonly setNotificationSettings = async (
    settings: GroupNotificationSettings,
  ) => {
    try {
      const groupId = await this.getGroupId();
      await Promise.all(
        Object.keys(settings)
          .filter((channel) => (settings as any)[channel])
          .map((channel) => {
            const notificationsReq = getNotificationSettingsRequest(
              (ApiTypes.v1.NotificationChannel as any)[channel],
              (settings as any)[channel],
            );
            return this.api.updateNotificationSettings(
              groupId!,
              notificationsReq,
            );
          }),
      );

      return this.getNotificationSettings();
    } catch (e) {
      console.error('Update Notifications: FAIL');
      this.errorLogger.log(e);
    }
  };

  private readonly getNotificationSettings = async () => {
    this.setProps({
      notificationSettings: null as any,
    });
    const initialNotificationSettings: GroupNotificationSettings = {
      [EMAIL as any]: undefined,
      [MOBILE as any]: undefined,
      [WEB as any]: undefined,
    } as any;
    const groupId = await this.getGroupId();

    await Promise.all(
      CHANNELS.map((channel) => {
        return this.api
          .getNotificationSettings(groupId!, channel)
          .then(({ data: { settings } }) => {
            initialNotificationSettings[channel] = getNotificationSettingsMap(
              settings!,
            );
          })
          .catch((e) => {
            this.errorLogger.log(e);
          });
      }),
    );

    this.setProps({
      notificationSettings: initialNotificationSettings,
    });
  };
}
