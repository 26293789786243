import { ReactionsApiTypes } from '@wix/social-groups-api/dist/src/types';

import { observable } from 'mobx';
import { list, object, primitive, serializable } from 'serializr';
import {
  IReaction,
  IReactions,
  IUserReaction,
  IUsersReacted,
  IUsersReactions,
} from '../../../types/IFeedItem';

class Reaction implements IReaction {
  @serializable reactionCode: string;

  constructor(reaction: ReactionsApiTypes.Reaction) {
    this.reactionCode = reaction.reactionCode!;
  }
}

export class UserReaction implements IUserReaction {
  @serializable userId: string;

  @serializable(object(Reaction)) reaction: Reaction;

  @serializable reactionTime: Date;

  constructor(userReaction: ReactionsApiTypes.UserReaction) {
    this.userId = userReaction.userId!;
    this.reactionTime = userReaction.reactionTime!;
    this.reaction = new Reaction(userReaction.reaction!);
  }

  equals(userReaction: ReactionsApiTypes.UserReaction) {
    const isSameUser = this.userId === userReaction.userId;
    const isSameReaction =
      this.reaction.reactionCode === userReaction.reaction!.reactionCode;

    return isSameUser && isSameReaction;
  }
}

class UsersReactions implements IUsersReactions {
  @serializable
  @observable
  total: number;

  @serializable(list(object(UserReaction)))
  @observable.shallow
  reactions: UserReaction[];

  constructor(usersReactions: ReactionsApiTypes.UsersReactions) {
    this.total = usersReactions.total!;
    this.reactions = usersReactions.userReactions!.map(
      (userReaction) => new UserReaction(userReaction),
    );
  }

  has(userReaction: ReactionsApiTypes.UserReaction) {
    return !!this.reactions.find((reaction) => reaction.equals(userReaction));
  }

  add(userReaction: ReactionsApiTypes.UserReaction) {
    this.total = this.reactions.push(new UserReaction(userReaction));
    return this.total;
  }

  remove(userReaction: ReactionsApiTypes.UserReaction) {
    try {
      const reactions = this.reactions.filter(
        (r) =>
          !(
            r.userId === userReaction.userId &&
            r.reaction.reactionCode === userReaction.reaction!.reactionCode
          ),
      );
      this.reactions = reactions;
      this.total = this.reactions.length;
    } catch (e) {
      console.log('UsersReactions.remove error');
    }

    return this.total;
  }

  getUserIds() {
    return this.reactions.map((r) => r.userId);
  }
}

class UsersReacted implements IUsersReacted {
  @serializable
  @observable
  total: number;

  @serializable(list(primitive())) @observable.shallow userIds: string[];

  constructor(usersReacted: ReactionsApiTypes.Users) {
    this.total = usersReacted.total!;
    this.userIds = usersReacted.userIds!;
  }
}

export class Reactions implements IReactions {
  @serializable total: number;

  @serializable(object(UsersReactions))
  @observable
  usersReactions: UsersReactions;

  @serializable(object(UsersReacted)) @observable usersReacted: UsersReacted;

  constructor(
    reactionsSummary: ReactionsApiTypes.ItemReactionsSummary = {
      total: 0,
      usersReactions: {
        total: 0,
        userReactions: [],
      },
      usersReacted: {
        total: 0,
        userIds: [],
      },
    },
  ) {
    this.total = reactionsSummary.total || 0;
    this.usersReactions = new UsersReactions(reactionsSummary.usersReactions!);
    this.usersReacted = new UsersReacted(reactionsSummary.usersReacted!);
  }

  add(userReaction: ReactionsApiTypes.UserReaction) {
    this.usersReactions.add(userReaction);
    this.setUsersReactedFromReaction();
    return this.total;
  }

  has(userReaction: ReactionsApiTypes.UserReaction) {
    return this.usersReactions.has(userReaction);
  }

  private setUsersReactedFromReaction() {
    const userIds = [...new Set(this.usersReactions.getUserIds())];
    this.usersReacted = new UsersReacted({ total: userIds.length, userIds });
    this.setTotalReactions();
  }

  remove(userReaction: ReactionsApiTypes.UserReaction) {
    this.usersReactions.remove(userReaction);
    this.setUsersReactedFromReaction();
    return this.total;
  }

  private setTotalReactions() {
    this.total = this.usersReacted.total;
  }

  getTotalReactions() {
    return this.usersReactions.total;
  }
}
