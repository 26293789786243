export function compareByFieldValue(field: string, value: string) {
  return (m1: any, m2: any) => {
    if (m1[field] === value && m2[field] !== value) {
      return -1;
    }
    if (
      (m1[field] === value && m2[field] === value) ||
      (m1[field] !== value && m2[field] !== value)
    ) {
      return 0;
    }

    return 1;
  };
}

export function uniqueStrings(strings: string[]) {
  const noDupStr = new Set(strings);
  return [...noDupStr];
}
