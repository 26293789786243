import { Controller } from '../Controller';
import { EVENTS_SCOPE, IEventsControllerState } from './EventsControllerProps';
import { EventsApi } from '@wix/social-groups-api/dist/src/services/eventsApi/EventsApi';
import { ControllerParams } from '@wix/yoshi-flow-editor';

export class EventsController extends Controller<IEventsControllerState> {
  private eventsApi!: EventsApi;

  protected state: IEventsControllerState = {
    events: null as any,
    limit: 100,
    offset: 0,
    total: 0,
  };

  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);

    this.setApi();

    this.onUserLogin(async () => {
      this.setApi();
    });
  }

  private setApi() {
    this.eventsApi = new EventsApi(this.getSiteToken()!, this.getApiBaseUrl());
  }

  setState(props: Partial<IEventsControllerState>) {
    this.state = { ...this.state, ...props };
    this.controllerConfig.setProps({
      [EVENTS_SCOPE]: {
        ...this.state,
        fetch: this.fetch.bind(this),
      },
    });

    return this.state;
  }

  pageReady(): Promise<void> {
    return Promise.resolve();
  }

  async fetch() {
    try {
      const groupId = await this.getGroupId();
      const { events, limit, offset, total } =
        await this.eventsApi.getGroupEvents(groupId!);

      this.setState({
        events,
        limit,
        offset,
        total,
        error: null as any,
      });
    } catch (error) {
      this.setState({ error: error.response.message });
    }
  }

  async getInitialProps() {
    return this.setState({});
  }
}
