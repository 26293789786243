import { CommentsApiTypes } from '@wix/social-groups-api/dist/src/types';
import { object, serializable } from 'serializr';

import { SocialIdentity, SocialText } from '../../../types/feed';
import { IComment, ICommentEntity } from '../../../types/IFeedItem';

class CommentEntity implements ICommentEntity {
  @serializable(object(SocialText)) text!: SocialText;
}

export class Comment implements IComment {
  @serializable commentId: string;

  @serializable(object(SocialIdentity)) createdBy: SocialIdentity;

  @serializable createdAt: Date;

  @serializable(object(CommentEntity)) comment: CommentEntity;

  constructor(comment: CommentsApiTypes.Comment) {
    this.commentId = comment.commentId!;
    this.createdAt = comment.createdAt!;

    this.createdBy = comment.createdBy as SocialIdentity;
    this.comment = comment.comment as ICommentEntity;
  }
}
